import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidator {
    static email(control: AbstractControl): ValidationErrors | null {
        const EMAIL_REGEXP = new RegExp(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        const email = control.value as string;
        if (EMAIL_REGEXP.test(email) != true) {
            if (email.includes(' ')) {
                // This is a special easter egg just for users with 'david' in
                // their email.
                if (email.includes('david')) {
                    return { customEmailSpacesDavid: true };
                }
                return { customEmailSpaces: true };
            }
            return { customEmail: true };
        }

        return null;
    }

    static range(min: number, max: number): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            if (c.value && (isNaN(c.value) || c.value < min || c.value > max)) {
                return { range: true };
            }
            return null;
        };
    }

    static isHexadecimal(control: AbstractControl): ValidationErrors | null {
        if (!control.value) {
            return null;
        }

        return /^[0-9a-fA-F]+$/.test(control.value)
            ? null
            : { hexadecimal: true };
    }

    static max(max): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const val = parseInt(control.value);
            return val > max ? { max: true } : null;
        };
    }

    static min(min): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const val = parseInt(control.value);
            return val < min ? { min: true } : null;
        };
    }
}
